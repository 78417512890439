import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import React from "react";

export const CarouselButton = ({ direction, onClick }) => {
  return (
    <button
      className={
        "btn btn-slide " +
        (direction === "left" ? "btn-slide-left" : "btn-slide-right")
      }
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={direction === "left" ? faChevronLeft : faChevronRight}
      />
    </button>
  );
};
