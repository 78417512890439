import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./css/project.css";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import { ProjectHeader } from "./components/ProjectHeader";
import { DisciplineList } from "./components/DisciplineList";
import { DisciplineProject } from "./components/DisciplineProject";
import { Modal } from "./components/Modal";

function Project({isDark, toggleDarkTheme}) {
  let { projectSlug } = useParams();

  const [disciplines, disciplinesSet] = useState([]);
  const [projectTitle, projectTitleSet] = useState("");
  const [disciplineSelected, disciplinesSelectedSet] = useState(0);
  const [
    disciplineSelectedHasSubDiscipline,
    disciplineSelectedHasSubDisciplineSet,
  ] = useState(false);
  const [projectId, projectIdSet] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [disciplineProject, disciplineProjectSet] = useState(
    window.innerWidth >= 768
  );
  const [disciplineList, disciplineListSet] = useState(true);
  const [homeLinkHidden, homeLinkHiddenSet] = useState(false);
  const [btnBackHidden, btnBackHiddenSet] = useState(true);
  const [projectHeaderColor, projectHeaderColorSet] = useState("#6c757d");
  const [fatherDiscipline, fatherDisciplineSet] = useState(0);
  const [grandFatherDiscipline, grandFatherDisciplineSet] = useState(0);
  const [dendrogramSource, dendrogramSourceSet] = useState("");

  const getProjectData = useCallback(
    (introIsHidden, selected = 0) => {
      let disciplineSelectedIndex = selected;
      if (JSON.parse(localStorage.getItem(projectSlug))) {
        let response = JSON.parse(localStorage.getItem(projectSlug));
        processProjectData(response, disciplineSelectedIndex, selected);
      } else {
        fetch(process.env.REACT_APP_BASE_API_URL + "projects/" + projectSlug)
          .then((response) => response.json())
          .then((response) => {
            //console.log("fetch getProject", response)
            processProjectData(response, disciplineSelectedIndex, selected);
            localStorage.setItem(projectSlug, JSON.stringify(response));
          });
      }
    },
    [projectSlug]
  );

  useEffect(() => {
    getProjectData(false);
  }, [projectSlug, getProjectData]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const processProjectData = (response, disciplineSelectedIndex, selected) => {    
    disciplinesSet(response.data.disciplines);
    projectTitleSet(response.data.title);
    dendrogramSourceSet(response.data.dendrogram);
    projectIdSet(response.data.project_id);

    if (selected !== 0) {
      disciplineSelectedIndex = response.data.disciplines.findIndex(
        (value) => value.id === selected
      );
    }

    disciplinesSelectedSet(
      parseInt(response.data.disciplines[disciplineSelectedIndex].id)
    );
    disciplineSelectedHasSubDisciplineSet(
      response.data.disciplines[disciplineSelectedIndex].has_sub_disciplines
    );
    disciplineListSet(true);
    homeLinkHiddenSet(false);
    btnBackHiddenSet(true);
  };

  const setProjectHeaderColor = (hex) => {
    projectHeaderColorSet(hex);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const setDiscipline = (evt) => {
    if (
      parseInt(evt.getAttribute("data-discipline-id")) !== disciplineSelected
    ) {
      disciplinesSelectedSet(parseInt(evt.getAttribute("data-discipline-id")));
      disciplineSelectedHasSubDisciplineSet(
        evt.getAttribute("data-has-sub-discipline") === "1"
      );
    }

    if (window.innerWidth < 1200) {
      disciplineProjectSet(true);
      disciplineListSet(false);
      homeLinkHiddenSet(disciplineList);
      btnBackHiddenSet(!disciplineList);
    }
    goToTop();
  };

  const handleBtnBackClick = (evt) => {
    let father = parseInt(evt.getAttribute("data-father"));
    let grandFather = parseInt(evt.getAttribute("data-grand-father"));
    
    // layout mobile
    if (window.innerWidth < 1200) {

      if (father !== 0 && !disciplineList) {        
        getSubdisciplines(father, projectId);
      } else if (grandFather === 0) {
        getProjectData(true, father);
        fatherDisciplineSet(0)
      }

      if (disciplineList && grandFather !== 0) {
        getSubdisciplines(grandFather, projectId);
      }
      disciplineProjectSet(false);
    } else {
      //layout desktop
      if (father === 0) {
        homeLinkHiddenSet(false);
        btnBackHiddenSet(true);
      }

      if (grandFather !== 0) {
        getSubdisciplines(grandFather, projectId, father);
      } else {
        getProjectData(father);
      }
    }    
  };

  const getSubdisciplines = (disciplineId, projectId, selected = null) => {
    homeLinkHiddenSet(true);
    btnBackHiddenSet(false);

    fetch(process.env.REACT_APP_BASE_API_URL + "sub-disciplines", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        discipline_id: disciplineId,
        project_id: projectId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log("getSubdisciplines", response,selected)
        fatherDisciplineSet(response.data.relatives.father);
        grandFatherDisciplineSet(response.data.relatives.grand_father);
        disciplinesSet(response.data.sub_disciplines);
        if (window.innerWidth < 1200) {
          disciplineListSet(true);
          disciplineProjectSet(false);
        }
        if (selected != null) {
          // search by key-value
          let disciplineSelectedIndex = response.data.sub_disciplines.findIndex(
            (value) => value.id === selected
          );
          disciplinesSelectedSet(
            response.data.sub_disciplines[disciplineSelectedIndex].id
          );
          disciplineSelectedHasSubDisciplineSet(
            response.data.sub_disciplines[disciplineSelectedIndex]
              .has_sub_disciplines
          );
        } else {
          disciplinesSelectedSet(response.data.sub_disciplines[0].id);
          disciplineSelectedHasSubDisciplineSet(
            response.data.sub_disciplines[0].has_sub_disciplines
          );
        }
      });
  };

  return (
    <div className={"bg-basal " + ( isDark ? "darkmode" : "")}>
      <NavBar isDark={isDark} setDarkTheme={toggleDarkTheme}/>
      <ProjectHeader
        projectTitle={projectTitle}
        homeLinkHidden={homeLinkHidden}
        btnBackHidden={btnBackHidden}
        handleBtnBackClick={handleBtnBackClick}
        projectHeaderColor={projectHeaderColor}
        fatherDiscipline={fatherDiscipline}
        grandFatherDiscipline={grandFatherDiscipline}
        isDark={isDark}
      />
      <section className="main-content">
        <DisciplineList
          disciplines={disciplines}
          visible={disciplineList}
          setDiscipline={setDiscipline}
          active={disciplineSelected}
          width={width}
          disciplinesSet={disciplinesSet}
          projectSlug={projectSlug}
          isDark={isDark}
        />
        <DisciplineProject
          disciplineId={disciplineSelected}
          projectId={projectId}
          visible={disciplineProject}
          width={width}
          hasSubDiscipline={disciplineSelectedHasSubDiscipline}
          setProjectHeaderColor={setProjectHeaderColor}
          getSubdisciplines={getSubdisciplines}
          isDark={isDark}
        />
      </section>
      
      {width < 992 && <Footer projectView={false}/> }
      <Modal
        modalId={"dendogramModal"}
        modalTitle={"Dendograma " + projectTitle}
        isImage={true}
        src={dendrogramSource}
        alt={"dendrogram_" + projectTitle}
      />
    </div>
  );
}

export default Project;
