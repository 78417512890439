import images from "./../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClouds, faStars } from "@fortawesome/pro-duotone-svg-icons"

export const NavBar = ({isDark, setDarkTheme}) => {

  return (
    <nav className="navbar navbar-inverse">
      <div className="container-fluid">
        <a className="navbar-brand d-flex align-items-center" href="/">
          <img
            src={isDark ? images.logoDark : images.logoLight}
            alt="logo"
            className="d-inline-block align-text-top mt-md-2 logo-rumi"
          />
          <span className="brand">Rumi</span>
        </a>
        <div className="d-flex ms-auto">
          <div className={"toggle toggle-transition" + ( isDark ? " dark" : "")} id="toggle" onClick={setDarkTheme}>
            <div className={"sun toggle-transition" + ( isDark ? " dark" : "")}>
              <div className={"moon toggle-transition" + ( isDark ? " dark" : "")} id="moon"></div>
            </div>
            <FontAwesomeIcon icon={faClouds} className={"toggle-transition toggle-icon" + ( isDark ? " dark" : "")} />
            <FontAwesomeIcon icon={faStars} className={"toggle-transition toggle-icon" + ( isDark ? " dark" : "")}/>
            <div className={"shade shade-inner toggle-transition" + ( isDark ? " dark" : "")}></div>
            <div className={"shade shade-middle toggle-transition" + ( isDark ? " dark" : "")}></div>
            <div className={"shade shade-outter toggle-transition" + ( isDark ? " dark" : "")}></div>            
          </div>
        </div>
      </div>
    </nav>
  );
};
