import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Gradient } from "./../assets/img/gradient.svg";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faXTwitter,
  faYoutube,
  faAmazon,
  faEtsy,
} from "@fortawesome/free-brands-svg-icons";

export const Footer = ({ projectView }) => {
  return (
    <footer className="d-flex justify-content-center align-items-center py-3 secondary-font-color">
      <div className="d-flex col-12 justify-content-center">
        <ul className="list-unstyled mt-3 col-12 fs-5">
          <li>
            <ul className="social-icons-list">
              <li className="social-icon">
                <a
                  target="_blank"
                  rel="noreferrer"
                  id="social-linkedin"
                  href="https://www.linkedin.com/company/proyecto-rumi/posts/?feedView=all"
                >
                  <FontAwesomeIcon
                    className="fa-gradient"
                    icon={faLinkedinIn}
                  />
                </a>
              </li>
              <li>
                <div className="dot"></div>
              </li>
              <li className="social-icon">
                <Gradient />
                <a
                  target="_blank"
                  rel="noreferrer"
                  id="social-instagram"
                  href="https://www.instagram.com/the_rumi_hub/"
                >
                  <FontAwesomeIcon
                    className="fill-gradient-instagram"
                    icon={faInstagram}
                  />
                </a>
              </li>
              <li>
                <div className="dot"></div>
              </li>
              <li className="social-icon">
                <a
                  target="_blank"
                  rel="noreferrer"
                  id="social-facebook"
                  href="https://web.facebook.com/profile.php?id=61553593654488"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
              <li>
                <div className="dot"></div>
              </li>
              <li className="social-icon">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://x.com/ExploradorRumi"
                >
                  <FontAwesomeIcon icon={faXTwitter} />
                </a>
              </li>
              <li>
                <div className="dot"></div>
              </li>
              <li className="social-icon">
                <a
                  target="_blank"
                  rel="noreferrer"
                  id="social-youtube"
                  href="https://www.youtube.com/@Rumi-hub"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </li>
            </ul>
          </li>
          <li>
            <ul className="social-icons-list">
              <li className="social-icon">
                <a
                  target="_blank"
                  rel="noreferrer"
                  id="store-amazon"
                  href="https://www.amazon.es/elefante-mil-miradas-análisis-multidisciplinario/dp/B0D9WV84JV"
                >
                  <FontAwesomeIcon className="fa-gradient" icon={faAmazon} />
                </a>
              </li>
              <li>
                <div className="dot"></div>
              </li>
              <li className="social-icon">
                <Gradient />
                <a
                  target="_blank"
                  rel="noreferrer"
                  id="store-etsy"
                  href="https://www.etsy.com/es/shop/ExploradorRumi"
                >
                  <FontAwesomeIcon className="fill-gradient" icon={faEtsy} />
                </a>
              </li>
            </ul>
          </li>
          <li
            className={projectView === true ? "footer-project" : "footer-main"}
          >
            <p>&copy; 2023 Rumi</p>
          </li>
          <li
            className={projectView === true ? "footer-project" : "footer-main"}
          >
            <p>contacto@rumiexplora.com</p>
          </li>
          <li
            className={projectView === true ? "footer-project" : "footer-main"}
          >
            <p>
              Made with{" "}
              <span className="heart-icon">
                <FontAwesomeIcon icon={faHeart} />
              </span>{" "}
              for students by students
            </p>
          </li>
          <li
            className={projectView === true ? "footer-project" : "footer-main"}
          >
            <p>Valparaíso, Chile</p>
          </li>
        </ul>
      </div>
    </footer>
  );
};
