import { Button } from "./Button";

export const AWSBanner = ({ innerWidth, imgSource, bannerIcon, link, isBlank, isBook }) => {  
  return (
    <section className={"banner-aws " + (isBook ? " banner-book" : "banner-poster")}>
      <div className="d-flex flex-column align-items-center justify-content-center banner-aws-left">
          <p className="josefin-slab banner-aws-text">
            Descubre el poder de la <br /> multidisciplinariedad
          </p>
          {innerWidth < 570 && (
             <Button content={"Quiero tenerlo!"} icon={bannerIcon} href={link} isBlank={isBlank} />
          )}
        </div>        
        {innerWidth >= 570 && (
          <div className="d-flex flex-column align-items-center banner-aws-right">
            <p className="banner-aws-text josefin-slab">
              {`Disponible en ${isBook ? "Amazon" : "Etsy"}`}
            </p>            
            <Button content={"Quiero tenerlo!"} icon={bannerIcon} href={link} isBlank={isBlank} />
          </div>
        )}
        <a href={link} target="_blank" rel="noreferrer"  className={isBook ? "banner-book-image" : "banner-poster-image"}>
          <img src={imgSource} alt="logo header" />
        </a>
    </section>
  );
};
