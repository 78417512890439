import "./css/abstract.css";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import { AWSBanner } from "./components/Banner";
import images from "./assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { faAmazon, faEtsy } from "@fortawesome/free-brands-svg-icons";

function Abstract({ isDark, toggleDarkTheme, windowSize }) {
  return (
    <div className={"bg-basal " + (isDark ? "darkmode" : "")}>
      {/* NavBar */}
      <NavBar isDark={isDark} setDarkTheme={toggleDarkTheme} />
      {/* Banner-AWS */}
      <AWSBanner
        innerWidth={windowSize.innerWidth}
        imgSource={images.bookTwo}
        bannerIcon={faAmazon}
        link="https://www.amazon.es/elefante-mil-miradas-análisis-multidisciplinario/dp/B0D9WV84JV"
        isBlank={false}
        isBook={true}
      />
      {/* Header */}
      <header className="abstract__header">
        <h1 className="abstract__title abstract__quote josefin-slab">
          <FontAwesomeIcon
            icon={faQuoteLeft}
            className="quote__icon quote__left"
          />
          Lo he repetido mil veces y no me canso de decirlo: el futuro es
          multidisciplinar
          <FontAwesomeIcon
            icon={faQuoteRight}
            className="quote__icon quote__right"
          />
        </h1>
        <div className="line__separator"></div>
        <p className="monserrat abstract__author">Eduard Punset</p>
      </header>
      <section className="abstract__section">
        <h2 className="abstract__title abstract__title-section lemon-milk">
          Nuestra Motivación
        </h2>
        <p className="abstract__paragraph monserrat">
          Explorador Rumi es un proyecto que busca promover un enfoque
          multidisciplinario para comprender las conexiones entre distintas
          áreas del conocimiento. Nuestra misión es ofrecer una herramienta
          innovadora que permita analizar cualquier objeto de estudio desde
          múltiples disciplinas, facilitando un entendimiento más holístico y
          profundo. A través de esta plataforma, pretendemos superar las
          barreras entre disciplinas y ofrecer una experiencia visual y dinámica
          para analizar temas complejos.
        </p>
        <p className="abstract__paragraph monserrat">
          En un mundo cada vez más conectado, los desafíos que enfrentamos no se
          limitan a una sola área del conocimiento. Identificar las conexiones
          entre diferentes campos es valioso tanto a nivel académico como
          esencial para la toma de decisiones informadas y la creación de
          soluciones innovadoras a problemas complejos en sectores como la
          educación, la investigación y el ámbito profesional. Aunque la
          tecnología ha avanzado, el enfoque interdisciplinario sigue siendo un
          reto pendiente. Explorador Rumi está diseñado para cerrar esa brecha
          al proporcionar una herramienta accesible, escalable e interactiva.
        </p>
        <p className="abstract__paragraph monserrat">
          Invitamos a estudiantes, educadores, investigadores y profesionales de
          diferentes áreas a usar esta plataforma para expandir sus
          perspectivas, fomentar el pensamiento crítico y aplicar un análisis
          multidimensional en sus procesos de aprendizaje y desarrollo
          profesional.
        </p>
      </section>
      <div className="line__wrapper">
        <div className="line__separator"></div>
      </div>
      <section className="abstract__section">
        <h2 className="abstract__title abstract__title-section lemon-milk">
          Las Disciplinas
        </h2>
        <p className="abstract__paragraph monserrat">
          Definimos una disciplina como un conjunto de conocimientos,
          principios, métodos y objetivos específicos que se utilizan para
          abordar y estudiar un aspecto particular del mundo. Estas disciplinas
          no solo se enfocan en elementos tangibles, como la materia o los
          organismos vivos, sino que también abarcan el mundo abstracto de las
          ideas y los conceptos construidos por las sociedades a lo largo del
          tiempo.
        </p>
        <p className="abstract__paragraph monserrat">
          Nuestro proyecto se basa en una compilación organizada de disciplinas
          humanas, estructuradas de manera modular para permitir un análisis más
          profundo de cualquier tema de interés. Esta estructura clasifica las
          disciplinas en Campos de conocimiento (categorías más amplias), tipos
          (agrupaciones lógicas), y disciplinas basales (fundamentales en el
          conocimiento humano). Esto nos permite un enfoque modular y
          secuencial, adaptado a diferentes niveles de complejidad.
        </p>
        <img
          src={isDark ? images.disciplineListDark : images.disciplineList}
          alt="lista disciplinas"
          className="abstract__image__alt"
        ></img>
        <p className="abstract__paragraph monserrat">
          Nos hemos inspirado en sistemas de clasificación reconocidos, como la{" "}
          <a
            href="https://skos.um.es/unesco6/00/html"
            target="_blank"
            rel="noreferrer"
            className="abstract__link"
          >
            Nomenclatura Internacional de la UNESCO
          </a>
          ; y el{" "}
          <a
            href="https://libguides.ala.org/catalogingtools/classification"
            target="_blank"
            rel="noreferrer"
            className="abstract__link"
          >
            Sistema Dewey
          </a>
          , pero también hemos creado una segunda categorización interna que
          facilita un enfoque más comprensivo y organizado para analizar
          fenómenos complejos. Esta categorización es flexible y evolutiva, lo
          que permite integrar nuevos conocimientos o disciplinas emergentes a
          medida que se desarrollan.
        </p>
      </section>
      <div className="line__wrapper">
        <div className="line__separator"></div>
      </div>
      <section className="abstract__section">
        <h2 className="abstract__title abstract__title-section lemon-milk">
          Metodología
        </h2>
        <p className="abstract__paragraph monserrat">
          Para estructurar el conocimiento de manera clara y coherente, hemos
          diseñado nuestra metodología basada en el sistema{" "}
          <a
            href="http://www.gregghenriques.com/tok.html"
            target="_blank"
            rel="noreferrer"
            className="abstract__link"
          >
            Tree of Knowledge (Árbol del Conocimiento)
          </a>{" "}
          de Gregg R. Henriques.
        </p>
        <img
          src={isDark ? images.tokDark : images.tok}
          alt="lista disciplinas"
          className="abstract__image__alt"
        ></img>
        <p className="abstract__paragraph monserrat">
          Este enfoque nos permite organizar el conocimiento en cinco categorías
          fundamentales que abarcan diferentes aspectos de la realidad:
        </p>
        <ul className="abstract__list">
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Materia:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              Abarca todo lo relacionado con la existencia física del universo,
              desde partículas subatómicas hasta estructuras cósmicas.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Vida:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              Se centra en los fenómenos relacionados con los organismos vivos,
              desde los procesos biológicos hasta la evolución de las especies.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Mente:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              Explora los procesos mentales y cognitivos, como el pensamiento,
              la percepción y la interacción de los seres conscientes con su
              entorno.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Cultura:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              Incluye los valores, creencias, costumbres y formas de
              organización social que caracterizan a las sociedades humanas.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Ciencia Aplicada:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              Se enfoca en el uso práctico del conocimiento para resolver
              problemas y generar avances en campos como la tecnología y la
              salud.
            </p>
          </li>
        </ul>
      </section>
      <div className="line__wrapper">
        <div className="line__separator"></div>
      </div>
      <section className="abstract__section">
        <h2 className="abstract__title abstract__title-section lemon-milk">
          Aplicación Práctica: Explorador Rumi en Acción
        </h2>
        <p className="abstract__paragraph monserrat">
          Explorador Rumi está diseñado para ser una herramienta útil no solo a
          nivel teórico, sino también en la práctica. Hemos creado un sitio web
          donde las personas puedan comprender que estudia cada disciplina y
          cuales son las relaciones con nuestro objeto de estudio. A través de
          nuestra plataforma, los usuarios pueden:
        </p>
        <ul className="abstract__list">
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper--no-title">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__paragraph-list-as-title monserrat">
                Analizar problemas complejos desde múltiples perspectivas,
                utilizando mapas visuales que muestran cómo interactúan
                diferentes disciplinas en torno a un tema.
              </p>
            </div>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper--no-title">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__paragraph-list-as-title monserrat">
                Aplicar el conocimiento a proyectos profesionales o académicos.
                Por ejemplo, si un investigador quiere analizar el impacto del
                cambio climático en la biodiversidad, podrá visualizar las
                conexiones entre disciplinas como la ecología, la economía y la
                política ambiental.
              </p>
            </div>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper--no-title">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__paragraph-list-as-title monserrat">
                Utilizar inteligencia artificial para interactuar con las
                disciplinas y obtener análisis iniciales de temas específicos. A
                medida que la IA evoluciona, nuestras capacidades para generar
                modelos más confiables y personalizados aumentarán, permitiendo
                un análisis más preciso y ajustado a las necesidades del
                usuario.
              </p>
            </div>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper--no-title">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__paragraph-list-as-title monserrat">
                La estructura disciplinaria de Explorador Rumi permite agregar
                nuevas disciplinas y especializaciones sin alterar la
                organización básica. Esto significa que la plataforma puede
                adaptarse fácilmente a nuevas necesidades o avances en
                diferentes campos.
              </p>
            </div>
          </li>
        </ul>
        <img
          src={
            windowSize.innerWidth < 768
              ? isDark
                ? images.projectViewMobileDark
                : images.projectViewMobile
              : isDark
              ? images.projectViewDark
              : images.projectView
          }
          alt="vista de proyecto"
          className="abstract__image"
        ></img>
      </section>
      <div className="line__wrapper">
        <div className="line__separator"></div>
      </div>
      <section className="abstract__section">
        <h2 className="abstract__title abstract__title-section lemon-milk">
          Una Herramienta Pedagógica
        </h2>
        <p className="abstract__paragraph monserrat">
          Nuestra metodología está diseñada como un recurso educativo adaptable
          a estudiantes, profesores y profesionales de diversos niveles. Sus
          principales características incluyen:
        </p>
        <ul className="abstract__list">
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Visualización histórica:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              La estructura disciplinaria sigue una secuencia temporal que
              permite comprender la evolución del conocimiento y el contexto
              histórico de cada tema.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Interdisciplinariedad:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              El sistema está diseñado para integrar múltiples áreas del
              conocimiento, lo que facilita el análisis de problemas complejos
              desde diferentes perspectivas.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Pensamiento crítico:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              Al explorar las relaciones entre disciplinas, los usuarios
              desarrollan habilidades de evaluación crítica y pueden abordar
              problemas desde enfoques multidimensionales.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Apoyo curricular:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              La estructura disciplinaria puede servir como base para la
              creación de programas educativos coherentes y estructurados,
              aplicables en diferentes contextos académicos.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Escalabilidad:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              A medida que surgen nuevas disciplinas, pueden integrarse sin
              problemas en la estructura existente, lo que asegura la evolución
              continua de la plataforma.
            </p>
          </li>
        </ul>
      </section>
      <div className="line__wrapper">
        <div className="line__separator"></div>
      </div>
      <section className="abstract__section">
        <h2 className="abstract__title abstract__title-section lemon-milk">
          Mapas Disciplinarios y Subdisciplinas
        </h2>
        <p className="abstract__paragraph monserrat">
          Cada disciplina ha sido moldeada por siglos de evolución y millones de
          contribuciones activas. En Explorador Rumi, hemos creado mapas
          visuales que fragmentan las disciplinas en subdisciplinas, permitiendo
          a los usuarios profundizar tanto como deseen en cualquier área del
          conocimiento.
        </p>
        <p className="abstract__paragraph monserrat">
          Esta fragmentación facilita un estudio especializado y detallado. Los
          usuarios pueden concentrarse en áreas específicas sin perder de vista
          las conexiones generales, lo que es valioso para quienes buscan
          especialización. Además, la fragmentación permite un análisis más
          completo de fenómenos complejos, brindando una perspectiva más
          comprensiva e integrada.
        </p>
        <img
          src={isDark ? images.dendogramDark : images.dendogram}
          alt="lista disciplinas"
        ></img>
      </section>
      <div className="line__wrapper">
        <div className="line__separator"></div>
      </div>
      <section className="abstract__section">
        <h2 className="abstract__title abstract__title-section lemon-milk">
          Ejemplo de Aplicación: Industria Gastronómica
        </h2>
        <p className="abstract__paragraph monserrat">
          La industria gastronómica, que abarca restaurantes, servicios de
          catering, bares y otros establecimientos de servicio de alimentos, es
          un sector dinámico y de gran alcance que impacta directamente en la
          vida cotidiana de millones de personas en todo el mundo. Al fragmentar
          esta disciplina en ramas le podemos ofrecer un análisis detallado de
          cada uno de estos campos. Esto permite:
        </p>
        <ul className="abstract__list">
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Especialización:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              Al identificar áreas clave como la gestión de restaurantes, la
              sostenibilidad o la cocina molecular, un o una profesional puede
              profundizar en una subdisciplina específica y convertirse en
              experto, lo que puede traducirse en un mejor desempeño, innovación
              en el área, o incluso en nuevas oportunidades laborales.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Innovación:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              La fragmentación permite una combinación creativa de
              conocimientos. Por ejemplo, al integrar conocimientos de nutrición
              y sostenibilidad, un chef podría desarrollar menús saludables y
              sostenibles que sean atractivos para un mercado más consciente de
              la salud y el medio ambiente.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Resolución de problemas:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              La organización disciplinaria facilita el análisis de problemas
              complejos desde múltiples ángulos. Si un restaurante enfrenta
              dificultades con la sostenibilidad, puede abordarlo desde una
              perspectiva de logística, ciencia de los alimentos, y marketing,
              logrando soluciones más integrales y efectivas.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">Mejor toma de decisiones:</p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              Al tener una visión completa de cómo las distintas subdisciplinas
              se interrelacionan, los líderes del sector pueden tomar decisiones
              más informadas sobre aspectos como la gestión de residuos, los
              ingredientes utilizados, o cómo mejorar la experiencia del cliente
              desde una perspectiva cultural.
            </p>
          </li>
          <li className="abstract__list-item">
            <div className="abstract__list-wrapper">
              <img
                src={isDark ? images.logoDark : images.logoLight}
                alt="logo"
                className="d-inline-block align-text-top abstract__list-icon"
              />
              <p className="abstract__list-title">
                Colaboración interdisciplinaria:
              </p>
            </div>
            <p className="abstract__paragraph-list monserrat">
              Permite a profesionales de diferentes áreas de la gastronomía
              colaborar de manera más eficiente, ya que cada uno puede aportar
              su visión especializada dentro del mapa general, fomentando la
              creación de productos o servicios más innovadores y completos.
            </p>
          </li>
        </ul>
        <img
          src={images.dendogramGastronomy}
          alt="dendograma gastronomia"
          className="abstract__image"
        />
        <p className="abstract__paragraph monserrat">
          Esta metodología ofrece beneficios para distintos campos
          disciplinarios y estamos trabajando en la creación de estos mapas.
        </p>
        <p className="abstract__paragraph monserrat">
          El gráfico de las artes visuales permite a artistas especializarse,
          innovar, resolver problemas creativos, tomar decisiones más informadas
          y colaborar de manera interdisciplinaria, enriqueciendo así sus
          procesos y proyectos.
        </p>
        <img
          src={images.dendogramArt}
          alt="dendograma artes visuales"
          className="abstract__image"
        />
        <p className="abstract__paragraph monserrat">
          El dendograma de Derecho sirve como guía de especialización,
          herramienta de estudio, y recurso didáctico para estudiantes y
          docentes, además de facilitar explicaciones a clientes
          en consultorías.
        </p>
        <img
          src={images.dendogramLaw}
          alt="dendograma derecho"
          className="abstract__image"
        />
        <p className="abstract__paragraph monserrat">
          En informática, los mapas visuales ayudan a descomponer la disciplina,
          facilitando el análisis desde múltiples perspectivas para abordar
          problemas técnicos complejos.
        </p>
        <img
          src={images.dendodogramIT}
          alt="dendograma informatica"
          className="abstract__image"
        />
        <p className="abstract__paragraph monserrat">
          En medicina, los mapas permiten visualizar las relaciones entre
          subdisciplinas, mejorando el análisis y la especialización en áreas
          clave.
        </p>
        <img
          src={images.dendogramMedicine}
          alt="dendograma medicina"
          className="abstract__image"
        />
      </section>
      <div className="line__wrapper">
        <div className="line__separator"></div>
      </div>
      <section className="abstract__section">
        <h2 className="abstract__title abstract__title-section lemon-milk">
          Invitación
        </h2>
        <p className="abstract__paragraph monserrat">
          Si te interesa nuestro proyecto, síguenos en nuestras redes sociales
          para obtener información complementaria sobre nuestra herramienta y
          las nuevas funcionalidades del sitio web. Próximamente, podrás
          registrarte como usuario para crear y colaborar en proyectos propios o
          existentes.
        </p>
        <p className="abstract__paragraph monserrat">
          Explorador Rumi nació en Valparaíso, Chile, como un proyecto
          autogestionado. Si te parece interesante y deseas apoyarnos, te
          invitamos a adquirir nuestro libro "Un elefante, mil miradas," donde
          se detalla nuestra investigación, metodología y motivaciones.
        </p>
        <p className="abstract__paragraph monserrat">
          ¡Gracias por ser parte de esta aventura multidisciplinaria!
        </p>
        <div className="abstract__about monserrat">
          <div className="rumi__member">
            <p className="rumi__member-details">Germán Osorio</p>
            <p className="rumi__member-details abstract__list-title">
              Coordinador e Investigador
            </p>
          </div>
          <div className="rumi__member">
            <p className="rumi__member-details">Nicolás Machuca</p>
            <p className="rumi__member-details abstract__list-title">
              Desarrollador
            </p>
          </div>
          <div className="rumi__member">
            <p className="rumi__member-details">Alex Vergara</p>
            <p className="rumi__member-details abstract__list-title">
              Creación Audiovisual
            </p>
          </div>
        </div>
      </section>
      {/* Banner-Etsy */}
      <AWSBanner
        innerWidth={windowSize.innerWidth}
        imgSource={images.posterBanner}
        bannerIcon={faEtsy}
        link="https://www.etsy.com/es/shop/ExploradorRumi"
        isBlank={false}
        isBook={false}
      />
      {/* Footer */}
      <Footer projectView={false} />
    </div>
  );
}

export default Abstract;
