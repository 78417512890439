import { React, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./css/reset.css";
import "./css/rumi.css";
import HomePage from "./HomePage";
import Project from "./Project";
import Abstract from "./Abstract";

function App() {

  const getWindowWidth = () => {
    const { innerWidth } = window;
    return { innerWidth };
  };


  const [isDark, isDarkSet] = useState();
  const [windowSize, windowSizeSet] = useState(getWindowWidth());

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("darkmode"))) {
      isDarkSet(JSON.parse(localStorage.getItem("darkmode")));
    } else {
      isDarkSet(false);
    }
    function handleWindowResize() {
      windowSizeSet(getWindowWidth());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const toggleDarkTheme = () => {
    if (JSON.parse(localStorage.getItem("darkmode"))) {
      isDarkSet(!JSON.parse(localStorage.getItem("darkmode")))
    } else {      
      isDarkSet(!isDark);
    }
    localStorage.setItem("darkmode", !isDark);
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage isDark={isDark} toggleDarkTheme={toggleDarkTheme} windowSize={windowSize} />} />
        <Route path="/projects/:projectSlug" element={<Project isDark={isDark} toggleDarkTheme={toggleDarkTheme}  windowSize={windowSize}/>} />
        <Route path="/abstract" element={<Abstract isDark={isDark} toggleDarkTheme={toggleDarkTheme}  windowSize={windowSize}/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
