import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/pro-solid-svg-icons";
import { ReactComponent as Dendogram } from "./../assets/img/dendograma.svg";
import React from "react";

export const ProjectHeader = ({
  projectTitle,
  btnBackHidden,
  homeLinkHidden,
  handleBtnBackClick,
  projectHeaderColor,
  fatherDiscipline,
  grandFatherDiscipline,
  isDark
}) => {
  return (
    <header
      className="d-flex justify-content-center align-items-center text-white col-12"
      id="project-title"
      style={{ backgroundColor: projectHeaderColor }}
    >
      <div
        className={
          "col-1 d-flex justify-content-center" +
          (btnBackHidden ? " hidden" : "")
        }
        id="btn-back-container"
      >
        <button
          type="button"
          className="btn btn-light btn-back"
          id="btn-back"
          onClick={(evt) => handleBtnBackClick(evt.currentTarget)}
          data-father={fatherDiscipline}
          data-grand-father={grandFatherDiscipline}
        >
          <FontAwesomeIcon icon={faBackward} />
        </button>
      </div>
      <div
        className={
          "col-1 d-flex justify-content-center" +
          (homeLinkHidden ? " hidden" : "")
        }
        id="home-link-container"
      >
        <a href="/" className="btn btn-light btn-back">
          <FontAwesomeIcon icon={faBackward} />
        </a>
      </div>
      <div className="col-8">
        <h1 className={"text-center py-2 project-title " + (isDark ? "dark" : "")} >{projectTitle}</h1>
      </div>
      <div className="col-1">
        <button
          data-bs-toggle="modal"
          data-bs-target="#dendogramModal"
          className="btn-dendogram"
        >
          <Dendogram className="dendogram-icon" />
        </button>
      </div>
    </header>
  );
};
