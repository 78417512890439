const images = {
	logoLight: require("./img/logo_light.png"),
  logoDark: require("./img/logo_dark.png"),
  book: require("./img/book.png"),
  bookTwo: require("./img/book2.png"),
  posterBanner: require("./img/poster_banner.png"),
  disciplineList: require("./img/discipline_list.png"),
  disciplineListDark: require("./img/discipline_list_dark.png"),
  tok: require("./img/tok.png"),
  tokDark: require("./img/tok_dark.png"),
  projectViewMobileDark: require("./img/project_view_mobile_dark.png"),
  projectViewMobile: require("./img/project_view_mobile.png"),
  projectView: require("./img/project_view.png"),
  projectViewDark: require("./img/project_view_dark.png"),
  dendogram: require("./img/dendogram.png"),
  dendogramDark: require("./img/dendogram_dark.png"),
  dendogramGastronomy: require("./img/dendogram_gastronomy.png"),
  dendogramArt: require("./img/dendogram_art.png"),
  dendogramLaw: require("./img/dendogram_law.png"),
  dendodogramIT: require("./img/dendogram_it.png"),
  dendogramMedicine: require("./img/dendogram_medicine.png")
};

export default images;
