import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Button = ({ content, icon, href, isBlank }) => {
  return (
    <a
      href={href}
      rel="noreferrer"
      target={isBlank ? "_self" : "_blank"}
      className="cta-banner-aws"
    >
      <p className="josefin-slab banner-aws-text">{content}</p>
      <FontAwesomeIcon icon={icon} className="banner-aws-icon" />
    </a>
  );
};
