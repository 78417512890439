import Slider from "react-slick";
import { CarouselButton } from "./CarouselButton";
import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

export default function Carousel({
  projects,
  toggleIntro,
  innerWidth,
  hideBubble,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  var settings = {
    lazyLoad: "ondemand",
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    dots: true,
    className: "slider-container",
    nextArrow: <CarouselButton direction="right" onClick={next} />,
    prevArrow: <CarouselButton direction="left" onClick={previous} />,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    projects !== null &&
    projects.length > 0 && (      
      <Slider
        {...settings}
        beforeChange={(currentSlide, nextSlide) => {
          hideBubble();
          setCurrentIndex(nextSlide);
        }}
      >
        {projects.map((project, index) => {
          let position = null;
          if (innerWidth < 768) {
            //mobile
            position = "center";
          } else {
            position =
              index === currentIndex
                ? "left"
                : index === currentIndex + 1
                ? "center"
                : "right";
          }
          return (
            <li key={project.id} className="p-0 m-0 project-card-container" data-id={index}>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="intro-icon"
                data-project-slug={project.slug}
                data-position={position}
                data-project-id={project.id}
                onClick={(evt) =>
                  toggleIntro(
                    parseInt(evt.currentTarget.getAttribute("data-project-id")),
                    evt.currentTarget.getAttribute("data-position"),
                    evt.currentTarget.getAttribute("data-project-slug")
                  )
                }
              />
              <a href={"/projects/" + project.slug} className="project-name">
                <div className="card project-card rounded-10">
                  <div className="card-body carousel position-relative">
                    <div className="card-image-container d-flex align-items-center">
                      <img src={project.thumbnail} alt={project.slug} />
                    </div>
                    <p className="reanie-beanie text-center mt-3 card-project-title">
                      {project.title}
                    </p>
                  </div>
                </div>
              </a>
            </li>
          );
        })}
      </Slider>
    )
  );
}
