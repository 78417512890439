import Slider from "react-slick";

export default function CardCarousel({ images }) {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    vertical: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    pauseOnHover: true
  };
  return (
    <Slider {...settings}>
      {images.map((image, index) => {
        return (
          <div className={""} key={index}>
            <img
              className="card col-12 rounded-10 slide-image"
              alt="discipline"
              src={image}
            />
          </div>
        );
      })}
    </Slider>
  );
}
