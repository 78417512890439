export const DisciplineListItem = ({
  discipline,
  setDiscipline,
  active,
  hasSubDiscipline,
  width,
  isDark
}) => {
  return (
    <li
      className={"discipline discipline-first" + (active ? " active" : "")}
      data-parent="false"
      data-discipline-id={discipline.id}
      data-has-sub-discipline={hasSubDiscipline ? 1 : 0}
      onClick={(evt) => setDiscipline(evt.currentTarget)}
    >
      <div
        className={
          (width < 1200 ? "card rounded-10 " : "d-flex align-items-center ") +
          "discipline-card "
        }
      >
        <img
          className="card-img-top pt-4 discipline-thumbnail"
          alt="discipline"
          src={discipline.thumbnail}
        />
        <div className={(width < 1200 ? "card-body " : "") + "discipline-body"}>
          <h2
            className={
              (width < 1200 ? "text-center px-1" : "") +
              "card-title discipline-title" +
              (active ? (isDark ? " active-discipline-dark" :" active-discipline") : "")
            }
          >
            {discipline.name}
          </h2>
          <p
            className={
              (width < 1200 ? "text-center " : "") +
              "card-title discipline-type" +
              (active ? (isDark ? " active-discipline-dark" :" active-discipline") : "")
            }
          >
            {discipline.field}
          </p>
        </div>
      </div>
    </li>
  );
};
