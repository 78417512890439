export const Modal = ({ modalId, modalTitle, isImage, src, alt, style }) => {
  let modalContent = isImage ? <img src={src} alt={alt} /> : null;

  return (
    <div
      className={"modal dendrogram-modal fade " + style}
      id={modalId}
      tabIndex="-1"
      aria-labelledby={modalId + "label"}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {modalTitle}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{modalContent}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
