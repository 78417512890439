import React, { useCallback, useState } from "react";
import { Card } from "./Card";
import { Footer } from "./Footer";

export const DisciplineProject = ({
  disciplineId,
  projectId,
  visible,
  setProjectHeaderColor,
  getSubdisciplines,
  hasSubDiscipline,
  width,
  isDark
}) => {
  const [cards, cardsSet] = useState([]);
  const [images, imagesSet] = useState([]);
  const [cardIndex, cardIndexSet] = useState(0);
  const [cardCount, cardCountSet] = useState(1);
  const [paginationVisible, paginationVisibleSet] = useState(false);

  const setCardIndex = (evt) => {
    if (parseInt(evt.getAttribute("data-card-id")) >= 0) {
      cardIndexSet(parseInt(evt.getAttribute("data-card-id")));
    } else {
      let action = evt.getAttribute("data-page-id");
      switch (action) {
        case "back":
          cardIndexSet(cardIndex - 1);
          break;
        case "full-back":
          cardIndexSet(0);
          break;
        case "forward":
          cardIndexSet(cardIndex + 1);
          break;
        case "full-forward":
          cardIndexSet(cardCount - 1);
          break;
        default:
          break;
      }
    }
  };

  const processDiscipline = useCallback(
    (response) => {
      cardsSet(response.data.cards);
      paginationVisibleSet(response.data.cards.length > 1);
      cardCountSet(response.data.cards.length);
      imagesSet(response.data.imagesMobile);
      setProjectHeaderColor(response.data.color);
    },
    [setProjectHeaderColor]
  );

  React.useEffect(() => {
    if (projectId !== 0) {
      if (JSON.parse(localStorage.getItem(projectId + "-" + disciplineId))) {
        processDiscipline(
          JSON.parse(localStorage.getItem(projectId + "-" + disciplineId))
        );
      } else {
        fetch(process.env.REACT_APP_BASE_API_URL + "projects/" + projectId, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ discipline_id: disciplineId }),
        })
          .then((response) => response.json())
          .then((response) => {
            processDiscipline(response);
            localStorage.setItem(
              projectId + "-" + disciplineId,
              JSON.stringify(response)
            );
          });
      }
    }
  }, [disciplineId, projectId, processDiscipline]);

  return (
    <div className={"mb-4" + (!visible ? " hidden" : "")} id="project">
      <div className="flex flex-column my-4" id="project-discipline">
        {/* CARDS */}
        <div className="d-flex mt-3 card-list">
          <ul className="list-unstyled col-12">
            <div
              className="d-flex justify-content-center card-container"
              id="card-container"
            >
              {cards.length > 0 ? (
                <Card
                  setCardIndex={setCardIndex}
                  cardCount={cardCount}
                  pagination={paginationVisible}
                  card={cards[cardIndex]}
                  selectedCard={cardIndex}
                  images={images}
                  hasSubDiscipline={hasSubDiscipline}
                  getSubdisciplines={getSubdisciplines}
                  disciplineId={disciplineId}
                  projectId={projectId}
                  width={width}
                  isDark={isDark}
                />
              ) : null}
            </div>
          </ul>
        </div>
      </div>
      { width > 992 && <Footer projectView={true}/>}
    </div>
  );
};
