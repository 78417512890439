import { MainSource } from "./MainSource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAngleRight,
  faAnglesRight,
  faAngleLeft,
} from "@fortawesome/pro-solid-svg-icons";
import CardCarousel from "./CardCarousel";
import { ReactComponent as SubDiscipline } from "./../assets/img/subdisciplina.svg";

export const Card = ({
  card,
  disciplineDefinition,
  pagination,
  cardCount,
  selectedCard,
  setCardIndex,
  images,
  hasSubDiscipline,
  getSubdisciplines,
  disciplineId,
  projectId,
  width, 
  isDark
}) => {
  let cardIndexArray = Array.from({ length: cardCount }, (_, i) => i + 1);
  let cardIndexArraySimple = cardIndexArray;
  if (cardIndexArray.length > 5) {
    if (selectedCard === 0) {
      cardIndexArraySimple.splice(2, cardIndexArraySimple.length - 4, "...");
    } else if (
      selectedCard < cardIndexArray.length - 5 &&
      cardIndexArray.length > 6
    ) {
      // 2 splices + ...
      cardIndexArraySimple.splice(0, selectedCard);
      cardIndexArraySimple.splice(2, cardIndexArraySimple.length - 4, "...");
    } else {
      //splice al principio de total - 4
      cardIndexArraySimple.splice(0, cardIndexArraySimple.length - 5);
    }
  }

  return (
    <li className="col-12">
      <div className={"card rounded-10 card-discipline" + ( width < 1200 ? " mb-4" : "") + (isDark ? " darkmode" : "")}>
        <div className={"card-header col-12" + (isDark ? " card-header-dark" : "")} >
          <div className="col-12 d-flex align-items-center justify-content-between">
            <div className="col-10">
              <p className="card-title fw-bold mt-2" id="card-title">
                {card.title}
              </p>
            </div>
            {hasSubDiscipline === true ? (
              <button
                className="col-2 d-flex justify-content-end"
                data-project-id={projectId}
                data-discipline-id={disciplineId}
                onClick={(evt) => {
                  if (hasSubDiscipline) {
                    getSubdisciplines(
                      parseInt(
                        evt.currentTarget.getAttribute("data-discipline-id")
                      ),
                      parseInt(
                        evt.currentTarget.getAttribute("data-project-id")
                      )
                    );
                  }
                }}
              >
                <SubDiscipline className={"subdiscipline-icon mt-2" + (isDark ? " primary-font-dark": "") }/>
              </button>
            ) : null}
          </div>
        </div>
        <div className="card-body">         
          {/* SLIDESHOW CARD */}
          <div className="slick-container">
            {images.length > 1 ? (
              <CardCarousel images={images} />
            ) : (
              <img
                className="card rounded-10 slide-image-single"
                alt="discipline"
                src={images[0]}
              />
            )}
          </div>
          {/* PARAGRAPHS */}
          <article className="card-content">
            {card.paragraphs.map((paragraph, index) => (
              <div key={index}>
                <p className={"card-text " + (index === 0 ? "card-text-first" : "")}>{paragraph.content}</p>
                <br />
              </div>
            ))}
          </article>
          {/* PAGINATION */}
          <div
            className={pagination === true ? null : "hidden"}
            id="pagination-container"
          >
            <ul className="pagination d-flex justify-content-center">
              <li className="pagination-item">
                <button
                  className="btn btn-light btn-pagination"
                  data-page-id="full-back"
                  disabled={selectedCard === 0}
                  onClick={(evt) => setCardIndex(evt.currentTarget)}
                >
                  <FontAwesomeIcon icon={faAnglesLeft} />
                </button>
              </li>
              <li className="pagination-item">
                <button
                  className="btn btn-light btn-pagination"
                  data-page-id="back"
                  disabled={selectedCard === 0}
                  onClick={(evt) => setCardIndex(evt.currentTarget)}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
              </li>
              <div
                id="pagination-custom"
                className="d-flex justify-content-center"
              >
                {pagination === true
                  ? cardCount > 5
                    ? cardIndexArraySimple.map((value, index) => {
                        return value === "..." ? (
                          <li
                            className="pagination-item d-flex justify-content-center align-items-center"
                            key={index}
                          >
                            <button className="btn btn-light btn-pagination cursor-normal">
                              {value}
                            </button>
                          </li>
                        ) : (
                          <li
                            className="pagination-item d-flex justify-content-center align-items-center"
                            key={index}
                          >
                            <button
                              onClick={(evt) => setCardIndex(evt.currentTarget)}
                              className={
                                "btn btn-light btn-pagination" +
                                (value === selectedCard + 1 ? " active" : "")
                              }
                              data-card-id={value - 1}
                            >
                              {value}
                            </button>
                          </li>
                        );
                      })
                    : cardIndexArray.map((value, index) => {
                        return (
                          <li
                            className="pagination-item d-flex justify-content-center align-items-center"
                            key={index}
                          >
                            <button
                              onClick={(evt) => setCardIndex(evt.currentTarget)}
                              className={
                                "btn btn-light btn-pagination" +
                                (index === selectedCard ? " active" : "")
                              }
                              data-card-id={value - 1}
                            >
                              {value}
                            </button>
                          </li>
                        );
                      })
                  : null}
              </div>
              <li className="pagination-item">
                <button
                  className="btn btn-light btn-pagination"
                  data-page-id="forward"
                  disabled={selectedCard === cardCount - 1}
                  onClick={(evt) => setCardIndex(evt.currentTarget)}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </li>
              <li className="pagination-item">
                <button
                  className="btn btn-light btn-pagination"
                  data-page-id="full-forward"
                  disabled={selectedCard === cardCount - 1}
                  onClick={(evt) => setCardIndex(evt.currentTarget)}
                >
                  <FontAwesomeIcon icon={faAnglesRight} />
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div
        className={"card-footer d-flex" + (isDark ? " card-header-dark" : "")}>
          <ul className="col-12 sources-list" id="sources-list">
            <MainSource source={card.sources[0]} />
            <div id="additional-sources"></div>
          </ul>
        </div>
      </div>
    </li>
  );
};
