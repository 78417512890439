import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DisciplineListItem } from "./DisciplineListItem";
import {
  faMagnifyingGlass,
  faArrowDownAZ,
  faArrowUpAZ,
} from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";

export const DisciplineList = ({
  disciplines,
  disciplinesSet,
  visible,
  setDiscipline,
  active,
  width,
  projectSlug,
  isDark
}) => {
  const [needle, needleSet] = useState("");
  const [sortActive, sortActiveSet] = useState(0);

  const sortClick = () => {
    if(sortActive <= 1) {
      sortDisciplines(sortActive)
      sortActiveSet(sortActive + 1)      
    }else {
      disciplinesSet((JSON.parse(localStorage.getItem(projectSlug))).data.disciplines)
      sortActiveSet(0)
    }
  }

  const sortDisciplines = (direction) => {    
    if(direction === 0) {
      disciplinesSet(disciplines.sort((a,b) => (new Intl.Collator('en')).compare(a.name, b.name)))
    } else {
      disciplinesSet(disciplines.reverse())
    }
  }

  return (
    <div
      className={"col-12 mb-4" + (!visible ? " hidden" : "")}
      id="disciplines"
    >
      {/* FORM */}
      <section className="my-4 search-form-container">
        <form className="search-form my-1">
          <div className="d-flex col-12 align-items-center">
            <div className="col-8 col-md-4 col-xl-10">
              <label className="visually-hidden" htmlFor="discipline-needle">
                Discipline
              </label>
              <div className="input-group">
                <div className={"input-group-text" + (isDark ? " card-header-dark" : "")}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <input
                  value={needle}
                  type="text"
                  className="form-control py-sm-3"
                  id="discipline-needle"
                  placeholder="Explora una disciplina"
                  onChange={(evt) => needleSet(evt.target.value.toLowerCase())}
                />
              </div>
            </div>
            <div className="col-1 ms-2 col-xl-2">
              <button type="button" className={"btn btn-filter " + (sortActive > 0 ? "active " : "") +  (isDark ? "btn-filter-sort-dark " : " btn-filter-sort ")}>
                <div className="sort_asc">
                  <FontAwesomeIcon icon={sortActive === 2 ? faArrowUpAZ : faArrowDownAZ} onClick={sortClick}/>
                </div>
              </button>
            </div>
          </div>
        </form>
      </section>
      {/* DISCIPLINE LIST */}
      <section
        className={
          (width < 1200 ? "flex justify-content-center " : "") +
          "col-12 discipline-container custom-scrollbar p-0"
        }
      >
        <ul className="discipline-list" id="discipline-list">
          {disciplines
            .filter((discipline) =>
              discipline.name.toLowerCase().includes(needle)
            )
            .map((discipline) => (
              <DisciplineListItem
                hasSubDiscipline={discipline.has_sub_disciplines}
                active={active === discipline.id}
                discipline={discipline}
                key={discipline.id}
                setDiscipline={setDiscipline}
                width={width}
                isDark={isDark}
              />
            ))}
        </ul>
      </section>
    </div>
  );
};
