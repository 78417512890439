import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/pro-solid-svg-icons";

export const MainSource = ({ source }) => {
  let sourceIcon =
    source.fontawesome === 1 ? (
      <div className="d-flex py-2 align-items-center">
        <FontAwesomeIcon
          style={{ color: source.color }}
          icon={Icons[source.icon]}
        />
        <a
          className="ms-2 card-source fw-bold"
          href={source.href}
          target="_blank"
          rel="noreferrer"
        >
          <p className="ms-2 card-source">{source.name}</p>
        </a>
      </div>
    ) : (
      <div className="d-flex py-2 align-items-center">
        <svg viewBox="0 0 41 41" width="25" height="25" fill="none">
          <path
            fill={source.color}
            width="100"
            height="100"
            d={source.icon}
          ></path>
        </svg>
        <p className="ms-2 card-source">{source.name}</p>
      </div>
    );

  return (
    <li className="d-flex justify-content-start">
      <div className="main-source col-12 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between col-12">
          {sourceIcon}
          <div className="col-2"></div>
        </div>
      </div>
    </li>
  );
};
